// src/components/Header.js
import React from 'react';
import { NavLink } from 'react-router-dom';

const Header = () => {
  return (
    <nav id='navv' className="navbar navbar-expand-lg navbar-light bg-light  ">
      <div className="container d-none d-md-block">
        <div className='hstack'>
        <a className="navbar-brand" href="#">Zumra360</a>
 
 <div className="collapse navbar-collapse"  >
   <ul className="navbar navbar-nav ">

     <NavLink to="/" activeClassName="active"  >
     <li className="nav-item">
       <a className="nav-link" href="#">Home</a>
     </li>
     </NavLink>
     <NavLink to="post" activeClassName="active" className="nav-item">
     <li className="nav-item">
       <a className="nav-link" href="#">Post</a>
     </li>
     </NavLink>
     <NavLink to="msg" activeClassName="active" className="nav-item">
     <li className="nav-item">
       <a className="nav-link" href="#">Message</a>
     </li>
     </NavLink>
     <NavLink to="favorites" activeClassName="active" className="nav-item">
     <li className="nav-item">
       <a className="nav-link" href="#">Favourites</a>
     </li>
     </NavLink>
     <NavLink to="account" activeClassName="active" className="nav-item">
     <li className="nav-item">
       <a className="nav-link" href="#">Account</a>
     </li>
     </NavLink>
   </ul>
 </div>
        </div>
      </div>
      <div>

      </div>
      <div className='navbar navbar-expand-lg navbar-light bg-light d-block d-md-none   '>
      <div className="d-flex align-items-center justify-content-center">
          <a className="navbar-brand" href="#">Zumra360</a>
        </div> 
      </div>
    </nav>
  ); 
};

export default Header;
