// src/components/MobileNav.js
import React from 'react';
import { NavLink } from 'react-router-dom';
import { FaHome, FaHeart, FaPlusCircle, FaUser } from 'react-icons/fa';
import { MdMessage } from "react-icons/md";


const MobileNav = () => {
  return (
      <div className="mobile-nav">
        <NavLink  to="/" activeClassName="active" className="nav-item">
          <FaHome className="icon" />
          <span>Home</span>
        </NavLink>
        <NavLink to="favorites" activeClassName="active" className="nav-item">
          <FaHeart className="icon" />
          <span>Favorites</span>
        </NavLink>
        <NavLink to="post" activeClassName="active" className="nav-item">
          <FaPlusCircle className="icon" />
          <span>Post</span>
        </NavLink>
        <NavLink to="msg" activeClassName="active" className="nav-item">
          <MdMessage className="icon" />
          <span>Message</span>
        </NavLink>
        <NavLink to="account" activeClassName="active" className="nav-item">
          <FaUser className="icon" />
          <span>Account</span>
        </NavLink>
      </div>
  );
};

export default MobileNav;
