import React from 'react';
import Header from './components/Header';
import SearchBar from './components/SearchBar';
import SideNav from './components/SideNav';
import { Outlet } from 'react-router-dom';
import Footer from './components/Footer';
import MobileNav from './components/MobileNav';

const Profile = ( ) => {
  return (
    <div>
       <Header  />
      <Outlet></Outlet>
      <Footer />

      <MobileNav />
       
    </div>
  );
};

export default Profile;