// src/App.js
import React from 'react';
import Header from './components/Header';
import SearchBar from './components/SearchBar';
import CategoryList from './components/CategoryList';
import ProductList from './components/ProductList';
import SideNav from './components/SideNav';
import Footer from './components/Footer';
import './App.css';
import Home from './home';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import PostComponent from './components/PostComponent';
import MessageComponent from './components/MessageComponent';
import AccountComponent from './components/AccountComponent';
import Profile from './profile';
import Favourites from './components/Favourites';

const App = () => (

  <BrowserRouter>
    <Routes>
      <Route path='/' element={<Home />}>
        <Route index element={<ProductList cat='All Category' />} />
        <Route path='Cars' element={<ProductList cat='Cars' />} />
        <Route path='house' element={<ProductList cat='House' />} />
        <Route path='Electronics' element={<ProductList cat='Electronics' />} />
        <Route path='HomeGarden' element={<ProductList cat='Home & Garden' />} />
        <Route path='HealthBeauty' element={<ProductList cat='Health & Beauty' />} />
        <Route path='Jobs' element={<ProductList cat='Jobs' />} />
        <Route path='Services' element={<ProductList cat='Services' />} />

      </Route>

      <Route path='/' element={<Profile />}>
        <Route index element={<ProductList cat='All Category' />} />
        <Route path='Post' element={<PostComponent />} />
        <Route path='msg' element={<MessageComponent />} />
        <Route path='account' element={<AccountComponent />} />
        <Route path='favorites' element={<Favourites />} />
      </Route>

      <Route path='mobile' element={<Profile />} >
      <Route index element={<ProductList cat='All Category' />} />
      </Route>

    </Routes>
  </BrowserRouter>

);

export default App;
