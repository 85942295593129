import React, { useState } from 'react';

const MessageComponent = () => {
  const [messages, setMessages] = useState([
    { id: 1, text: 'Hello! How can I help you?', sender: 'support' },
    { id: 2, text: 'I am interested in the item you posted.', sender: 'user' },
  ]);
  const [newMessage, setNewMessage] = useState('');

  const handleSend = (e) => {
    e.preventDefault();
    if (newMessage.trim() === '') return;
    setMessages([...messages, { id: Date.now(), text: newMessage, sender: 'user' }]);
    setNewMessage('');
  };

  return (
    <div className="message-container">
      <h2>Messages</h2>
      <div className="message-list">
        {messages.map((msg) => (
          <div key={msg.id} className={`message-item ${msg.sender}`}>
            <p>{msg.text}</p>
          </div>
        ))}
      </div>
      <form onSubmit={handleSend}>
        <input
          type="text"
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          placeholder="Type a message"
        />
        <button type="submit">Send</button>
      </form>
    </div>
  );
};

export default MessageComponent;
