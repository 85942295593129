// src/components/ProductList.js
import React, { useEffect, useState } from 'react';
import ProductCard from './ProductCard';


const ProductList = ({cat}) => {

  const products = [
    {
      title: 'Toyota Camry 2010',
      description: 'Well maintained Toyota Camry for sale...',
      image: 'https://via.placeholder.com/150',
      price: '5,000'
    },
    {
      title: 'iPhone 12 Pro',
      description: 'Brand new iPhone 12 Pro available...',
      image: 'https://via.placeholder.com/150',
      price: '1,200'
    },
    {
      title: 'Toyota Camry 2010',
      description: 'Well maintained Toyota Camry for sale...',
      image: 'https://via.placeholder.com/150',
      price: '5,000'
    },
    {
       
        title: 'Toyota Camry 2010',
        description: 'Well maintained Toyota Camry for sale...',
        image: 'https://via.placeholder.com/150',
        price: '5,000'
      },
      {
        title: 'iPhone 12 Pro',
        description: 'Brand new iPhone 12 Pro available...',
        image: 'https://via.placeholder.com/150',
        price: '1,200'
      },
      {
        title: 'Toyota Camry 2010',
        description: 'Well maintained Toyota Camry for sale...',
        image: 'https://via.placeholder.com/150',
        price: '5,000'
      },
      {
        title: 'iPhone 12 Pro',
        description: 'Brand new iPhone 12 Pro available...',
        image: 'https://via.placeholder.com/150',
        price: '1,200'
      },
      {
        title: 'Toyota Camry 2010',
        description: 'Well maintained Toyota Camry for sale...',
        image: 'https://via.placeholder.com/150',
        price: '5,000'
      },
      {
        title: 'iPhone 12 Pro',
        description: 'Brand new iPhone 12 Pro available...',
        image: 'https://via.placeholder.com/150',
        price: '1,200'
      },
      {
        title: 'Toyota Camry 2010',
        description: 'Well maintained Toyota Camry for sale...',
        image: 'https://via.placeholder.com/150',
        price: '5,000'
      },
      {
        title: 'iPhone 12 Pro',
        description: 'Brand new iPhone 12 Pro available...',
        image: 'https://via.placeholder.com/150',
        price: '1,200'
      },
        {
      title: 'Toyota Camry 2010',
      description: 'Well maintained Toyota Camry for sale...',
      image: 'https://via.placeholder.com/150',
      price: '5,000'
    },
    {
      title: 'iPhone 12 Pro',
      description: 'Brand new iPhone 12 Pro available...',
      image: 'https://via.placeholder.com/150',
      price: '1,200'
    },
    {
      title: 'Toyota Camry 2010',
      description: 'Well maintained Toyota Camry for sale...',
      image: 'https://via.placeholder.com/150',
      price: '5,000'
    },
    {
      title: 'iPhone 12 Pro',
      description: 'Brand new iPhone 12 Pro available...',
      image: 'https://via.placeholder.com/150',
      price: '1,200'
    },
       
    // Add more products
  ];

  const [newCat, setNewCat] = useState(cat);

  const [isLoading , setIsLoading] = useState(false);



  useEffect(()=>{
    setNewCat(cat)
  },[cat])


  useEffect(()=>{
    // alert('before')/
    // setTimeout( ()=> setIsLoading(!isLoading), 3000)
    setIsLoading(true)
    

    setTimeout(() => {
      setIsLoading(false) // Set it back to false after the delay
    }, 3000);

  
    // alert('after')
    
  },[cat])

  return (
    <div className="container">
      <h4>{cat}</h4>

     { isLoading ? (
        <div>
          

{products.map((product, index) => (
    <div className="col-6 col-md-4 mb-4" key={index} style={{float:'left'}} > {/* col-6 for 2 columns on mobile, col-md-4 for 3 columns on desktop */}
      <div class="skeleton-card ">
        <div class="skeleton-image"></div>
        <div class="skeleton-info">
          <div class="skeleton-title"></div>
          <div class="skeleton-price"></div>
          <div class="skeleton-description"></div>
        </div>
      </div>
    </div>
  ))}
        </div>

) : (
  <div className="row">
  {products.map((product, index) => (
    <div className="col-6 col-md-4 mb-4" key={index}> {/* col-6 for 2 columns on mobile, col-md-4 for 3 columns on desktop */}
      <ProductCard product={product} />
    </div>
  ))}
</div>
)}

     
    </div>
  );
};

export default ProductList;
