// src/components/Footer.js
import React from 'react';

const Footer = () => {
  return (
    <div id='footer1' className="bg-dark text-white py-4 mt-5 ">
      <div className="container">
        <div className="row">
          <div className="col-md-3">
            <h5>About</h5>
            <ul className="list-unstyled">
              <li><a href="#" className="text-white">About Us</a></li>
              <li><a href="#" className="text-white">Careers</a></li>
              <li><a href="#" className="text-white">Press</a></li>
            </ul>
          </div>
          <div className="col-md-3">
            <h5>Support</h5>
            <ul className="list-unstyled">
              <li><a href="#" className="text-white">Help Center</a></li>
              <li><a href="#" className="text-white">Safety Tips</a></li>
              <li><a href="#" className="text-white">Contact Us</a></li>
            </ul>
          </div>
          <div className="col-md-3">
            <h5>Connect</h5>
            <ul className="list-unstyled">
              <li><a href="#" className="text-white">Facebook</a></li>
              <li><a href="#" className="text-white">Twitter</a></li>
              <li><a href="#" className="text-white">Instagram</a></li>
            </ul>
          </div>
          <div className="col-md-3">
            <h5>Download App</h5>
            <ul className="list-unstyled">
              <li><a href="#" className="text-white">iOS</a></li>
              <li><a href="#" className="text-white">Android</a></li>
            </ul>
          </div>
        </div>
        <hr className="bg-light" />
        <div className="text-center">
          <p className="mb-0">&copy; 2024 Zumra360. All rights reserved.</p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
