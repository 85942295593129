// src/components/SearchBar.js
import React from 'react';

const SearchBar = () => {
  return (
    <div className="container my-3 d-none d-md-block" style={{paddingTop:'80px'}}>
      <div className="row justify-content-md-center">
        <div className="col-md-8">
          <form className="d-flex">
            <input type="search" className="form-control me-2" placeholder="Search for anything..." />
            <button id='searchBtn' className="btn btn-primary" type="submit">Search</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SearchBar;
