import React from 'react';

const AccountComponent = () => {
  const user = {
    username: 'john_doe',
    email: 'john@example.com',
    joinedDate: 'January 10, 2023',
  };

  return (
    <div className="account-container">
      <h2>My Account</h2>
      <div className="account-info">
        <p><strong>Username:</strong> {user.username}</p>
        <p><strong>Email:</strong> {user.email}</p>
        <p><strong>Joined:</strong> {user.joinedDate}</p>
      </div>
      <button className="logout-btn">Log Out</button>
    </div>
  );
};

export default AccountComponent;
