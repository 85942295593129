// src/components/ProductCard.js
import React from 'react';

const ProductCard = ({ product }) => {
  return (
    <div className="card product-card">
      <div className="image-container">
        <img src={product.image} alt={product.title} className="card-img-top" />
      </div>
      <div className="card-body">
        <h5 className="card-title">{product.title}</h5>
        <p className="card-text price">${product.price}</p>
        <p className="card-description">{product.description}</p>
        <a href="#" className="btn btn-primary">View Details</a>
      </div>
    </div>
  );
};

export default ProductCard;
