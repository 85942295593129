import React from 'react';
// import './Favourites.css'; // Assuming you're going to add custom styles here

const favorites = [
  {
    id: 1,
    title: 'Toyota Camry 2019',
    price: '$20,000',
    description: 'Excellent condition, low mileage, perfect family car.',
    imageUrl: 'https://via.placeholder.com/150', // Replace with actual image
  },
  {
    id: 2,
    title: 'MacBook Pro 2020',
    price: '$1,500',
    description: 'Barely used, still under warranty, comes with accessories.',
    imageUrl: 'https://via.placeholder.com/150',
  },
  // Add more favorite products as needed
];

const Favourites = () => {
  return (
    <div className="container mt-4">
      <h3 className="text-uppercase mb-4">Your Favorites</h3>

      {favorites.length > 0 ? (
        favorites.map((product) => (
          <div key={product.id} className="card favourite-card mb-3 p-3">
            <div className="row no-gutters align-items-center">
              <div className="col-md-3">
                <img src={product.imageUrl} alt={product.title} className="img-fluid" />
              </div>
              <div className="col-md-9">
                <div className="card-body">
                  <h5 className="card-title">{product.title}</h5>
                  <p className="card-text text-muted">{product.price}</p>
                  <p className="card-text">{product.description}</p>
                </div>
              </div>
            </div>
          </div>
        ))
      ) : (
        <p>No favorites added yet!</p>
      )}
    </div>
  );
};

export default Favourites;
