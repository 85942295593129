import React from 'react';
import Header from './components/Header';
import SearchBar from './components/SearchBar';
import SideNav from './components/SideNav';
import { Outlet } from 'react-router-dom';
import Footer from './components/Footer';
import MobileNav from './components/MobileNav';

const Home = ( ) => {
  return (
    <div>
       <Header/>
       <SearchBar/>
       <div className="container my-4">
        <div className="row">
          <div className="col-md-3">
            <SideNav />
          </div>
          <div className="col-md-9">
          <Outlet></Outlet>
          </div>
        </div>
      </div>
      <Footer />
      <MobileNav />
       
    </div>
  );
};

export default Home;