// src/components/SideNav.js
import React from 'react';
import { NavLink } from 'react-router-dom';
import { FaCar, FaHome, FaLaptop, FaSeedling, FaTshirt, FaHeartbeat, FaBriefcase, FaCog } from 'react-icons/fa';

// Simulating categories with ad counts
const categories = [
  { name: 'Vehicles', adsCount: 11233, route : 'cars'},
  { name: 'Real Estate', adsCount: 8523, route : 'House' },
  { name: 'Electronics', adsCount: 9432, route : 'Electronics' },
  { name: 'Home & Garden', adsCount: 6234 , route : 'HomeGarden'},
  { name: 'Fashion', adsCount: 5301 , route : 'Fashion'},
  { name: 'Health & Beauty', adsCount: 3904 , route : 'HealthBeauty'},
  { name: 'Jobs', adsCount: 7546 , route : 'Jobs'},
  { name: 'Services', adsCount: 2109 , route : 'Services'},
];



// Simulating categories with ad counts
const categoriesMob = [
  { name: 'Vehicles', adsCount: 11233, route: 'cars', icon: <FaCar /> },
  { name: 'Real Estate', adsCount: 8523, route: 'House', icon: <FaHome /> },
  { name: 'Electronics', adsCount: 9432, route: 'Electronics', icon: <FaLaptop /> },
  { name: 'Home & Garden', adsCount: 6234, route: 'HomeGarden', icon: <FaSeedling /> },
  { name: 'Fashion', adsCount: 5301, route: 'Fashion', icon: <FaTshirt /> },
  { name: 'Health & Beauty', adsCount: 3904, route: 'HealthBeauty', icon: <FaHeartbeat /> },
  { name: 'Jobs', adsCount: 7546, route: 'Jobs', icon: <FaBriefcase /> },
  { name: 'Services', adsCount: 2109, route: 'Services', icon: <FaCog /> },
];


const SideNav = () => {
  return (
    <div className="sidenav bg-light p-3">
      <div className='d-none d-md-block
'>
      <ul className="nav flex-column">
        {categories.map((category, index) => (
          <li className="nav-item mb-1" key={index}> 
            <NavLink to={category.route} activeClassName="active" className="nav-item">
            <a className="nav-link category-link" href="#">
              {category.name}
              <span className="ad-count">{category.adsCount.toLocaleString()} ads</span>
            </a>
            </NavLink>
           
          </li>
        ))}
      </ul>

      <h6 className="text-uppercase mt-4">Other Sections</h6>
      <ul className="nav flex-column">
        <li className="nav-item mb-1">
          <a className="nav-link category-link" href="#">
            Deals of the Day
          </a>
        </li>
        <li className="nav-item mb-1">
          <a className="nav-link category-link" href="#">
            Latest Products
          </a>
        </li>
        <li className="nav-item mb-1">
          <a className="nav-link category-link" href="#">
            Top Sellers
          </a>
        </li>
      </ul>
      </div>


      <div className="mobile-icon-nav d-block d-md-none p-3 ">
      <h6 className="text-uppercase mb-3">Categories</h6>
      <div className="icon-grid">
        {categoriesMob.map((category, index) => (
          <NavLink to={category.route} activeClassName="active" className="icon-item" key={index}>
                      <div className="icon-card">
              <div className="icon-container">
                <div className="icon">{category.icon}</div>
                <div className="icon-label">{category.name}</div>
              </div>
            </div>
          </NavLink>
        ))}
      </div>

      </div>
    </div>
  );
};

export default SideNav;
